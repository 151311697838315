import React from "react";
import { Heart } from "lucide-react";

const TrackInfo = ({
  track,
  isFavorite,
  onToggleFavorite,
  onToggleDetails,
  showDetails,
}) => (
  <>
    <h2 className="text-3xl font-bold mb-2">{track.name}</h2>
    <h3 className="text-xl text-amber-500 font-bold uppercase tracking-wider mb-6">
      {track.artist}
    </h3>
    <div className="mb-6 flex justify-between items-center">
      <button
        onClick={onToggleFavorite}
        className="flex items-center space-x-2 hover:text-amber-500 transition-colors"
      >
        <Heart
          className={`w-6 h-6 ${
            isFavorite ? "fill-amber-500 stroke-amber-500" : "stroke-current"
          }`}
        />
      </button>
      <button
        onClick={onToggleDetails}
        className="hover:text-amber-500 transition-colors"
      >
        {showDetails ? "Hide Details" : "Show Details"}
      </button>
    </div>
  </>
);

export default TrackInfo;
