import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { User, LogOut } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import BackgroundNoiseSelector from "./components/BackgroundNoiseSelector";
import { useAuth } from "./AuthContext";
import { TodoInput, TodoList } from "./components/TodoInput";
import TrackControls from "./components/TrackControls";
import TrackInfo from "./components/TrackInfo";
import Timer from "./components/Timer";
import SpeedModeSelector from "./components/SpeedModeSelector";
import VolumeControl from "./components/VolumeControl";
import BreathingAnimation from "./components/BreathingAnimation";
import SpeedAndGenreSelector from "./components/SpeedModeSelector";
import { useAudio } from "./hooks/useAudio";
import { useTimer } from "./hooks/useTimer";

const tracks = [
  {
    name: "Sunset Vibes",
    genre: "lofi chill",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
    audioSrc: "/music/Sunset Vibes.mp3",
    details: "Relaxing tunes perfect for watching the sunset.",
  },
  {
    name: "Night Stroll",
    genre: "lofi chill",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
    audioSrc: "/music/Night Stroll.mp3",
    details: "Smooth beats for a peaceful night walk.",
  },
  {
    name: "Lazy Afternoon",
    genre: "lofi chill",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
    audioSrc: "/music/Lazy Afternoon.mp3",
    details: "Perfect for unwinding on a lazy afternoon.",
  },
  {
    name: "City Lights",
    genre: "lofi chill",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
    audioSrc: "/music/City Lights.mp3",
    details: "Chill beats inspired by the city's night lights.",
  },
  {
    name: "Morning Coffee",
    genre: "lofi chill",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
    audioSrc: "/music/Morning Coffee.mp3",
    details: "Perfect tunes to enjoy with your morning coffee.",
  },
  {
    name: "Rainy Days",
    genre: "lofi chill",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
    audioSrc: "/music/Rainy Days.mp3",
    details: "Soothing music for a rainy day.",
  },
  {
    name: "Chill Breeze",
    genre: "lofi chill",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
    audioSrc: "/music/Chill Breeze.mp3",
    details: "Relaxing tunes carried by a gentle breeze.",
  },
  {
    name: "Starlit Night",
    genre: "lofi chill",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
    audioSrc: "/music/Starlit Night.mp3",
    details: "Perfect for stargazing on a clear night.",
  },
  {
    name: "Summer Chill",
    genre: "lofi chill",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
    audioSrc: "/music/Summer Chill.mp3",
    details: "Just chill nothing more",
  },
  {
    name: "Wawes",
    genre: "lofi chill",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
    audioSrc: "/music/Wawes.mp3",
    details: "Just chill nothing more",
  },
  {
    name: "Sunshine Wawes",
    genre: "lofi chill",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
    audioSrc: "/music/Sunshine Waves.mp3",
    details: "Just chill nothing more",
  },
  {
    name: "Forest Walk",
    genre: "lofi chill",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
    audioSrc: "/music/Forest Walk.mp3",
    details: "Calming beats for a peaceful forest walk.",
  },
  {
    name: "Daydream",
    genre: "lofi chill",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
    audioSrc: "/music/Daydream.mp3",
    details: "Music to accompany your daydreams.",
  },
  {
    name: "Mellow Mood",
    genre: "lofi chill",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
    audioSrc: "/music/Mellow Mood.mp3",
    details: "Relaxing tunes for a mellow mood.",
  },
  {
    name: "Dreamy Nights",
    genre: "lofi chill",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1499561385668-5ebdb06a79bc?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dHJvcGljYWx8ZW58MHx8MHx8fDA%3D",
    audioSrc: "/music/Dreamy Nights.mp3",
    details: "Perfect for drifting off to dreamland.",
  },

  {
    name: "Bursting Through the Night",
    genre: "classical",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1570798932653-f4504ac37782?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    audioSrc: "/music/Bursting Through the Night.mp3",
    details: "Konchivawa",
  },
  {
    name: "Rhytm Class",
    genre: "classical",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1570798932653-f4504ac37782?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    audioSrc: "/music/Rhytm Class.mp3",
    details: "Konchivawa",
  },
  {
    name: "Into the Storm",
    genre: "classical",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1570798932653-f4504ac37782?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    audioSrc: "/music/Into the Storm.mp3",
    details: "Konchivawa",
  },
  {
    name: "Whispers of Sun",
    genre: "classical",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1570798932653-f4504ac37782?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    audioSrc: "/music/Whispers of Sun.mp3",
    details: "Konchivawa",
  },
  {
    name: "Whispers of the Heart",
    genre: "classical",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1570798932653-f4504ac37782?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    audioSrc: "/music/Whispers of the Heart.mp3",
    details: "Konchivawa",
  },
  {
    name: "Echoes in the past",
    genre: "classical",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1570798932653-f4504ac37782?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    audioSrc: "/music/Echoes in the past.mp3",
    details: "Konchivawa",
  },
  {
    name: "Echoes in the Rain",
    genre: "classical",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1570798932653-f4504ac37782?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    audioSrc: "/music/Echoes in the Rain.mp3",
    details: "Konchivawa",
  },
  {
    name: "Sakura Dreams",
    genre: "lofi",
    artist: "AI",
    albumArt:
      "https://images.unsplash.com/photo-1570798932653-f4504ac37782?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    audioSrc: "/music/JapanLofi1.mp3",
    details: "Konchivawa",
  },
  {
    name: "Night Stroll in Shibuya",
    artist: "AI",
    genre: "lofi",
    albumArt:
      "https://images.unsplash.com/photo-1702118177614-c4867d0cf7cd?q=80&w=2617&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    audioSrc: "/music/JapanLofi2.mp3",
    details: "Konchivawa",
  },
  {
    name: "Whispers of Kyoto",
    artist: "Japanese Lofi",
    genre: "lofi",

    albumArt:
      "https://plus.unsplash.com/premium_photo-1669876271015-55e215f60bc4?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    audioSrc: "/music/JapanLofi3.mp3",
    details: "Konchivawa",
  },
  {
    name: "Eternal Sunset",
    artist: "Japanese Lofi",
    genre: "lofi",

    albumArt:
      "https://images.unsplash.com/photo-1524721696987-b9527df9e512?q=80&w=2533&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    audioSrc: "/music/JapanLofi4.mp3",
    details: "Konchivawa",
  },
  {
    name: "Rainy Day Reflections",
    artist: "Japanese Lofi",
    genre: "lofi",
    albumArt:
      "https://images.unsplash.com/photo-1481966115753-963394378f23?q=80&w=2426&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    audioSrc: "/music/JapanLofi5.mp3",
    details: "Konchivawa",
  },
  {
    name: "Tokyo Twilight",
    artist: "Japanese Lofi",
    genre: "lofi",
    albumArt:
      "https://images.unsplash.com/flagged/photo-1567400358593-9e6382752ea2?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    audioSrc: "/music/JapanLofi6.mp3",
    details: "Konchivawa",
  },
  {
    name: "J l",
    artist: "Lofi Beats",
    genre: "lofi",
    albumArt:
      "https://images.unsplash.com/photo-1718027808460-7069cf0ca9ae?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    audioSrc: "/music/song1.mp3",
    details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    name: "Mo nigh",
    artist: "Lofi Beats",
    genre: "lofi",
    albumArt:
      "https://images.unsplash.com/photo-1717501218221-1da14c0f365e?q=80&w=2532&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    audioSrc: "/music/song2.mp3",
    details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    name: "Just Peaches",
    artist: "Lofi Beats",
    genre: "lofi",
    albumArt:
      "https://images.unsplash.com/photo-1717501218221-1da14c0f365e?q=80&w=2532&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    audioSrc: "/music/song3.mp3",
    details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    name: "Just 4",
    artist: "Lofi Beats",
    genre: "lofi",
    albumArt:
      "https://images.unsplash.com/photo-1717501218221-1da14c0f365e?q=80&w=2532&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    audioSrc: "/music/song4.mp3",
    details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    name: "5 nos",
    artist: "Lofi Beats",
    genre: "lofi",
    albumArt:
      "https://images.unsplash.com/photo-1718027808460-7069cf0ca9ae?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    audioSrc: "/music/song5.mp3",
    details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    name: "6 and beyond",
    artist: "Lofi Beats",
    genre: "lofi",
    albumArt:
      "https://images.unsplash.com/photo-1718027808460-7069cf0ca9ae?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    audioSrc: "/music/song6.mp3",
    details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    name: "Lucky 7",
    artist: "Lofi Beats",
    genre: "lofi",
    albumArt:
      "https://images.unsplash.com/photo-1718027808460-7069cf0ca9ae?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    audioSrc: "/music/song7.mp3",
    details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  // ... other tracks
];

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};

const MusicPlayer = () => {
  const { user, signOut } = useAuth();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [speedMode, setSpeedMode] = useState("normal");
  const [showBreathingAnimation, setShowBreathingAnimation] = useState(false);
  const [todos, setTodos] = useState([]);
  const [genre, setGenre] = useState("lofi");
  const profileMenuRef = useRef(null);

  const getTracksForGenre = useCallback((currentGenre) => {
    return tracks.filter((track) => track.genre === currentGenre);
  }, []);

  const currentGenreTracks = useMemo(
    () => getTracksForGenre(genre),
    [genre, getTracksForGenre]
  );

  const [backgroundNoises, setBackgroundNoises] = useState([
    { name: "Rain", audioSrc: "/music/rain.mp3", isActive: false, volume: 0.5 },
    {
      name: "Ocean",
      audioSrc: "/music/ocean.mp3",
      isActive: false,
      volume: 0.5,
    },
    {
      name: "Fireplace",
      audioSrc: "/music/fireplace.mp3",
      isActive: false,
      volume: 0.5,
    },
  ]);

  const filteredTracks = useMemo(() => {
    return tracks.filter((track) => track.genre === genre);
  }, [genre]);

  const {
    isPlaying,
    trackIndex,
    volume,
    currentTime,
    duration,
    audioRef,
    togglePlayback,
    skipToNext,
    skipToPrevious,
    setVolume,
    setCurrentTime,
    setBackgroundNoiseVolume,
  } = useAudio(currentGenreTracks, 1, backgroundNoises);

  const {
    minutes: timerMinutes,
    seconds: timerSeconds,
    isActive: timerActive,
    setMinutes: setTimerMinutes,
    setSeconds: setTimerSeconds,
    startTimer,
    stopTimer,
  } = useTimer();

  useEffect(() => {
    if (currentGenreTracks.length > 0) {
      // Reset to the first track of the new genre
      audioRef.current.src = currentGenreTracks[0].audioSrc;
      setCurrentTime(0);
      if (isPlaying) {
        audioRef.current.play();
      }
    }
  }, [genre, currentGenreTracks, audioRef, setCurrentTime, isPlaying]);

  const handleClickOutside = (event) => {
    if (
      profileMenuRef.current &&
      !profileMenuRef.current.contains(event.target)
    ) {
      setShowProfileMenu(false);
    }
  };

  useEffect(() => {
    if (showProfileMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showProfileMenu]);

  const hideBreathingAnimation = () => {
    setShowBreathingAnimation(false);
  };

  useEffect(() => {
    if (timerActive && timerMinutes === 0 && timerSeconds === 0) {
      stopTimer();
      if (isPlaying) {
        togglePlayback();
      }
    }
  }, [
    timerActive,
    timerMinutes,
    timerSeconds,
    stopTimer,
    isPlaying,
    togglePlayback,
  ]);

  useEffect(() => {
    let animationTimer;
    if (isPlaying) {
      setShowBreathingAnimation(true);
      animationTimer = setTimeout(() => {
        setShowBreathingAnimation(false);
      }, 8000);
    }

    return () => {
      if (animationTimer) {
        clearTimeout(animationTimer);
      }
    };
  }, [isPlaying]);

  const handleGenreChange = useCallback((newGenre) => {
    setGenre(newGenre);
  }, []);

  const handleAddTodo = useCallback(
    (todoText) => {
      const timerMatch = todoText.match(/for (\d+)\s?min/i);
      if (timerMatch) {
        const minutes = parseInt(timerMatch[1], 10);
        setTimerMinutes(minutes);
        setTimerSeconds(0);
        startTimer();
        togglePlayback();
      }

      setTodos((prevTodos) => [
        ...prevTodos,
        {
          text: todoText.replace(/for \d+\s?min/i, "").trim(),
          completed: false,
        },
      ]);
    },
    [setTimerMinutes, setTimerSeconds, startTimer, togglePlayback]
  );

  const toggleTodo = useCallback((index) => {
    setTodos((prevTodos) =>
      prevTodos.map((todo, i) =>
        i === index ? { ...todo, completed: !todo.completed } : todo
      )
    );
  }, []);

  const handleNoiseToggle = useCallback(
    (noiseName) => {
      setBackgroundNoises((noises) =>
        noises.map((noise, index) => {
          if (noise.name === noiseName) {
            const newNoise = { ...noise, isActive: !noise.isActive };
            setBackgroundNoiseVolume(
              index,
              newNoise.isActive ? newNoise.volume : 0
            );
            return newNoise;
          }
          return noise;
        })
      );
    },
    [setBackgroundNoiseVolume]
  );

  const handleVolumeChange = useCallback(
    (noiseName, newVolume) => {
      setBackgroundNoises((noises) =>
        noises.map((noise, index) => {
          if (noise.name === noiseName) {
            setBackgroundNoiseVolume(index, newVolume);
            return { ...noise, volume: newVolume };
          }
          return noise;
        })
      );
    },
    [setBackgroundNoiseVolume]
  );

  const handleLogout = async () => {
    try {
      await signOut();
      // Redirect to login page or handle logout success
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const toggleFavorite = useCallback(() => setIsFavorite((prev) => !prev), []);
  const toggleDetails = useCallback(() => setShowDetails((prev) => !prev), []);
  const handleTimelineClick = useCallback(
    (event) => {
      const timelineWidth = event.currentTarget.clientWidth;
      const clickPosition = event.nativeEvent.offsetX;
      const newTime = (clickPosition / timelineWidth) * duration;
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    },
    [duration, audioRef, setCurrentTime]
  );

  const currentTrack = tracks[trackIndex];

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center p-4 text-white relative"
      style={{
        backgroundImage: `url(${currentTrack.albumArt})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* Black background overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-75 z-0"></div>

      {/* Blur effect overlay */}
      <div className="absolute inset-0 backdrop-blur-md z-0"></div>

      <div className="relative z-10 w-full max-w-4xl">
        <div className="p-6">
          {/* Todo and User Profile */}
          <div className="flex items-start justify-between mb-6">
            <div className="flex-grow mr-4">
              <TodoInput onAddTodo={handleAddTodo} />
            </div>
            <div className="relative flex-shrink-0 pt-1" ref={profileMenuRef}>
              <button
                onClick={() => setShowProfileMenu(!showProfileMenu)}
                className="rounded-full bg-white p-2 text-black hover:bg-gray-200 transition-colors"
              >
                <User className="w-6 h-6" />
              </button>
              <AnimatePresence>
                {showProfileMenu && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                  >
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                    >
                      <div className="px-4 py-2 text-sm text-gray-700">
                        {user?.email}
                      </div>
                      <button
                        onClick={handleLogout}
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      >
                        <LogOut className="w-4 h-4 inline-block mr-2" />
                        Logout
                      </button>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>

          <TodoList todos={todos} toggleTodo={toggleTodo} />

          {/* Speed Mode Selector */}
          <div className="flex flex-col md:flex-row items-center justify-between mb-6">
            <div className="text-center md:text-left mb-4 md:mb-0">
              <span className="text-amber-500 font-bold text-sm uppercase tracking-widest">
                High Neural Effect
              </span>
              <h2 className="text-2xl font-bold">Deep Work</h2>
            </div>
            <SpeedAndGenreSelector
              speedMode={speedMode}
              genre={genre}
              onSpeedModeChange={setSpeedMode}
              onGenreChange={handleGenreChange}
            />
          </div>

          {/* Album Art and Track Controls */}
          <div className="mb-8 relative">
            <img
              src={currentTrack.albumArt}
              alt={currentTrack.name}
              className="w-full rounded-lg shadow-lg"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <TrackControls
                isPlaying={isPlaying}
                onTogglePlayback={togglePlayback}
                onSkipPrevious={skipToPrevious}
                onSkipNext={skipToNext}
              />
            </div>
            <span className="absolute top-0 right-0 bg-rose-500 text-white text-xs font-bold uppercase px-2 py-1 rounded-br-lg">
              NEW
            </span>
          </div>

          {/* Track Info */}
          <TrackInfo
            track={currentTrack}
            isFavorite={isFavorite}
            onToggleFavorite={toggleFavorite}
            onToggleDetails={toggleDetails}
            showDetails={showDetails}
          />

          {/* Volume Control */}
          <VolumeControl volume={volume} onVolumeChange={setVolume} />

          {/* Track Details */}
          <AnimatePresence>
            {showDetails && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                className="mb-8 overflow-hidden"
              >
                <h3 className="text-xl font-bold mb-2">Details</h3>
                <p>{currentTrack.details}</p>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Timer */}
          <Timer
            minutes={timerMinutes}
            seconds={timerSeconds}
            isActive={timerActive}
            onStart={startTimer}
            onStop={stopTimer}
            onMinutesChange={setTimerMinutes}
            onSecondsChange={setTimerSeconds}
          />

          {/* Background Noise Selector */}
          <BackgroundNoiseSelector
            backgroundNoises={backgroundNoises}
            onNoiseToggle={handleNoiseToggle}
            onVolumeChange={handleVolumeChange}
          />

          {/* Timeline */}
          <div className="mb-4 flex justify-between text-sm">
            <span>{formatTime(currentTime)}</span>
            <span>{formatTime(duration)}</span>
          </div>

          <div
            className="mb-8 w-full h-2 bg-gray-600 rounded-full cursor-pointer relative"
            onClick={handleTimelineClick}
          >
            <div
              className="h-full bg-amber-500 rounded-full relative"
              style={{ width: `${(currentTime / duration) * 100}%` }}
            >
              <div className="absolute right-0 top-1/2 transform translate-x-1/2 -translate-y-1/2 w-4 h-4 bg-white rounded-full shadow-md"></div>
            </div>
          </div>
        </div>
      </div>

      <BreathingAnimation
        isVisible={showBreathingAnimation}
        onClose={hideBreathingAnimation}
      />

      <audio ref={audioRef} src={currentTrack.audioSrc} onEnded={skipToNext} />
    </div>
  );
};

export default MusicPlayer;
