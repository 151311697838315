import React from "react";
import { Zap } from "lucide-react";
import { motion } from "framer-motion";

const Timer = ({
  minutes,
  seconds,
  isActive,
  onStart,
  onStop,
  onMinutesChange,
  onSecondsChange,
}) => (
  <div className="mb-8 flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4">
    <div className="flex items-center space-x-2 bg-white rounded-lg p-2">
      <input
        type="number"
        min="0"
        max="59"
        value={minutes}
        onChange={(e) => onMinutesChange(parseInt(e.target.value))}
        className="w-12 p-1 text-black text-center rounded-lg"
      />
      <span className="text-black">:</span>
      <input
        type="number"
        min="0"
        max="59"
        value={seconds}
        onChange={(e) => onSecondsChange(parseInt(e.target.value))}
        className="w-12 p-1 text-black text-center rounded-lg"
      />
    </div>
    <button
      className={`flex items-center space-x-2 px-4 py-2 rounded-lg ${
        isActive ? "bg-red-500" : "bg-green-500"
      } text-white cursor-pointer transition-colors`}
      onClick={onStart}
    >
      <motion.div
        animate={isActive ? { rotate: 360 } : { rotate: 0 }}
        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
      >
        <Zap className="w-6 h-6" />
      </motion.div>
      <span className="text-xl font-bold">
        {`${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`}
      </span>
    </button>
    <button
      onClick={onStop}
      className="px-4 py-2 rounded-lg bg-gray-600 text-white hover:bg-gray-700 transition-colors"
    >
      Stop
    </button>
  </div>
);

export default Timer;
