import React from "react";
import { Play, Pause, SkipForward, SkipBack } from "lucide-react";

const TrackControls = ({
  isPlaying,
  onTogglePlayback,
  onSkipPrevious,
  onSkipNext,
}) => (
  <div className="flex items-center space-x-8">
    <button
      onClick={onSkipPrevious}
      className="text-white/70 hover:text-white transition-colors"
    >
      <SkipBack className="w-8 h-8 stroke-current" />
    </button>
    <button
      onClick={onTogglePlayback}
      className="rounded-full bg-white text-black flex items-center justify-center w-16 h-16 shadow-xl hover:scale-105 active:scale-100 transition-transform"
    >
      {isPlaying ? (
        <Pause className="w-6 h-6" />
      ) : (
        <Play className="w-6 h-6 ml-1" />
      )}
    </button>
    <button
      onClick={onSkipNext}
      className="text-white/70 hover:text-white transition-colors"
    >
      <SkipForward className="w-8 h-8 stroke-current" />
    </button>
  </div>
);

export default TrackControls;
