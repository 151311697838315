import { useState, useEffect, useRef, useCallback } from "react";

export const useAudio = (tracks, initialVolume = 1, backgroundNoises = []) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [trackIndex, setTrackIndex] = useState(0);
  const [volume, setVolume] = useState(initialVolume);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);
  const backgroundAudioRefs = useRef(backgroundNoises.map(() => new Audio()));

  const togglePlayback = useCallback(() => setIsPlaying((prev) => !prev), []);
  const skipToNext = useCallback(() => {
    setTrackIndex((prevIndex) => (prevIndex + 1) % tracks.length);
    setIsPlaying(true);
  }, [tracks.length]);
  const skipToPrevious = useCallback(() => {
    setTrackIndex(
      (prevIndex) => (prevIndex - 1 + tracks.length) % tracks.length
    );
    setIsPlaying(true);
  }, [tracks.length]);

  useEffect(() => {
    const audio = audioRef.current;
    audio.volume = volume;
    isPlaying ? audio.play().catch(console.error) : audio.pause();
  }, [isPlaying, trackIndex, volume]);

  useEffect(() => {
    const audio = audioRef.current;
    const updateTime = () => setCurrentTime(audio.currentTime);
    const setAudioData = () => {
      setDuration(audio.duration);
      setCurrentTime(audio.currentTime);
    };

    audio.addEventListener("loadedmetadata", setAudioData);
    audio.addEventListener("timeupdate", updateTime);

    return () => {
      audio.removeEventListener("loadedmetadata", setAudioData);
      audio.removeEventListener("timeupdate", updateTime);
    };
  }, []);

  useEffect(() => {
    backgroundNoises.forEach((noise, index) => {
      const audio = backgroundAudioRefs.current[index];
      audio.src = noise.audioSrc;
      audio.loop = true;
      audio.volume = noise.volume;

      if (noise.isActive) {
        audio
          .play()
          .catch((error) =>
            console.error("Error playing background noise:", error)
          );
      } else {
        audio.pause();
      }
    });

    return () => {
      backgroundAudioRefs.current.forEach((audio) => audio.pause());
    };
  }, [backgroundNoises]);

  const setBackgroundNoiseVolume = useCallback((index, newVolume) => {
    backgroundAudioRefs.current[index].volume = newVolume;
  }, []);

  return {
    isPlaying,
    trackIndex,
    volume,
    currentTime,
    duration,
    audioRef,
    togglePlayback,
    skipToNext,
    skipToPrevious,
    setVolume,
    setCurrentTime,
    setBackgroundNoiseVolume,
  };
};
