import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Cloud, Waves, Flame } from "lucide-react";

const BackgroundNoiseSelector = ({
  backgroundNoises,
  onNoiseToggle,
  onVolumeChange,
}) => {
  const getIcon = (noiseName) => {
    switch (noiseName.toLowerCase()) {
      case "rain":
        return <Cloud className="w-8 h-8" />;
      case "ocean":
        return <Waves className="w-8 h-8" />;
      case "fireplace":
        return <Flame className="w-8 h-8" />;
      default:
        return null;
    }
  };

  const handleVolumeChange = (noiseName, e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const y = e.clientY - rect.top;
    const percentage = 1 - y / rect.height;
    onVolumeChange(noiseName, Math.max(0, Math.min(1, percentage)));
  };

  return (
    <div className="mb-8">
      <h3 className="text-lg font-semibold mb-4">Background Ambience</h3>
      <div className="grid grid-cols-3 gap-4">
        {backgroundNoises.map((noise) => (
          <motion.div
            key={noise.name}
            className={`relative flex items-center justify-center p-4 rounded-xl transition-all overflow-hidden group`}
            style={{
              backgroundColor: noise.isActive
                ? `rgba(245, 158, 11, ${0.3 + noise.volume * 0.7})`
                : "rgba(31, 41, 55, 0.8)",
              color: noise.isActive ? "white" : "rgb(209, 213, 219)",
            }}
            whileHover={{ scale: 1.05 }}
          >
            <button
              onClick={() => onNoiseToggle(noise.name)}
              className="w-full h-full flex flex-col items-center justify-center"
            >
              {getIcon(noise.name)}
              <span className="mt-2 text-sm font-medium">{noise.name}</span>
            </button>

            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0, x: 10 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 10 }}
                transition={{ duration: 0.2 }}
                className="absolute top-0 right-0 bottom-0 w-8 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity"
              >
                <div
                  className="h-3/4 w-2 bg-gray-600 rounded-full overflow-hidden cursor-pointer rotate-180"
                  onClick={(e) => handleVolumeChange(noise.name, e)}
                >
                  <motion.div
                    className="w-full bg-white rounded-full"
                    style={{
                      height: `${noise.volume * 100}%`,
                      top: 0,
                      position: "relative",
                    }}
                    layoutId={`volume-${noise.name}`}
                  />
                </div>
              </motion.div>
            </AnimatePresence>
          </motion.div>
        ))}
      </div>
      <p className="text-xs text-gray-400 mt-4 text-center">
        Tip: Adjust volume by clicking on the vertical bar.
      </p>
    </div>
  );
};

export default BackgroundNoiseSelector;
