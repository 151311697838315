import React, { useState } from "react";
import { Volume2, VolumeX } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

const VolumeControl = ({ volume, onVolumeChange }) => {
  const [showVolumeSlider, setShowVolumeSlider] = useState(false);

  return (
    <div className="relative mb-6">
      <button
        onClick={() => setShowVolumeSlider(!showVolumeSlider)}
        className="hover:text-amber-500 transition-colors"
      >
        {volume === 0 ? (
          <VolumeX className="w-6 h-6" />
        ) : (
          <Volume2 className="w-6 h-6" />
        )}
      </button>
      <AnimatePresence>
        {showVolumeSlider && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 bg-gray-800 rounded-lg p-2"
          >
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={(e) => onVolumeChange(parseFloat(e.target.value))}
              className="w-24 accent-amber-500"
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default VolumeControl;
