import React from "react";

const SpeedAndGenreSelector = ({
  speedMode,
  genre,
  onSpeedModeChange,
  onGenreChange,
}) => {
  const speedModes = ["normal", "slow", "creative", "programming"];
  const genres = [
    "lofi",
    "lofi chill",
    "classical",
    "jazz",
    "ambient",
    "nature",
  ];

  return (
    <div className="flex flex-col sm:flex-row gap-4">
      <div className="flex-1">
        <label
          htmlFor="speed-mode"
          className="block text-sm font-medium text-gray-300 mb-2"
        >
          Speed Mode
        </label>
        <select
          id="speed-mode"
          value={speedMode}
          onChange={(e) => {
            onSpeedModeChange(e.target.value);
          }}
          className="bg-gray-700 text-white text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5"
        >
          {speedModes.map((mode) => (
            <option key={mode} value={mode}>
              {mode.charAt(0).toUpperCase() + mode.slice(1)}
            </option>
          ))}
        </select>
      </div>
      <div className="flex-1">
        <label
          htmlFor="genre"
          className="block text-sm font-medium text-gray-300 mb-2"
        >
          Genre
        </label>
        <select
          id="genre"
          value={genre}
          onChange={(e) => {
            onGenreChange(e.target.value);
          }}
          className="bg-gray-700 text-white text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5"
        >
          {genres.map((g) => (
            <option key={g} value={g}>
              {g.charAt(0).toUpperCase() + g.slice(1)}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SpeedAndGenreSelector;
