import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import * as THREE from "three";

const ImmersiveBreathingGlobe = ({ isVisible, onClose }) => {
  const [stage, setStage] = useState("breathing");
  const globeRef = useRef(null);
  const sceneRef = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    if (isVisible && stage === "breathing") {
      const scene = new THREE.Scene();
      sceneRef.current = scene;
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      const renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true,
      });
      renderer.setSize(window.innerWidth, window.innerHeight);
      globeRef.current.appendChild(renderer.domElement);

      const geometry = new THREE.SphereGeometry(5, 64, 64);
      const material = new THREE.ShaderMaterial({
        uniforms: {
          time: { value: 0 },
          colorA: { value: new THREE.Color(0xff6b6b) },
          colorB: { value: new THREE.Color(0x4ecdc4) },
        },
        vertexShader: `
          varying vec2 vUv;
          void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }
        `,
        fragmentShader: `
          uniform float time;
          uniform vec3 colorA;
          uniform vec3 colorB;
          varying vec2 vUv;
          
          void main() {
            vec3 color = mix(colorA, colorB, sin(time + vUv.y * 10.0) * 0.5 + 0.5);
            gl_FragColor = vec4(color, 0.8);
          }
        `,
        transparent: true,
      });
      const globe = new THREE.Mesh(geometry, material);
      scene.add(globe);

      // Glow effect
      const glowGeometry = new THREE.SphereGeometry(5.2, 64, 64);
      const glowMaterial = new THREE.ShaderMaterial({
        uniforms: {
          coefficient: { value: 0.5 },
          power: { value: 2 },
          glowColor: { value: new THREE.Color(0x7f00ff) },
        },
        vertexShader: `
          varying vec3 vNormal;
          void main() {
            vNormal = normalize(normalMatrix * normal);
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }
        `,
        fragmentShader: `
          uniform float coefficient;
          uniform float power;
          uniform vec3 glowColor;
          varying vec3 vNormal;
          void main() {
            float intensity = pow(coefficient - dot(vNormal, vec3(0, 0, 1.0)), power);
            gl_FragColor = vec4(glowColor, 1.0) * intensity;
          }
        `,
        side: THREE.BackSide,
        blending: THREE.AdditiveBlending,
        transparent: true,
      });
      const glowMesh = new THREE.Mesh(glowGeometry, glowMaterial);
      scene.add(glowMesh);

      const light = new THREE.PointLight(0xffffff, 1, 100);
      light.position.set(10, 10, 10);
      scene.add(light);

      camera.position.z = 10;

      let scale = 1;
      let growing = true;

      const animate = (time) => {
        requestAnimationFrame(animate);
        material.uniforms.time.value = time / 1000;

        if (growing) {
          scale += 0.003;
          if (scale >= 1.2) growing = false;
        } else {
          scale -= 0.003;
          if (scale <= 1) growing = true;
        }

        globe.scale.set(scale, scale, scale);
        glowMesh.scale.set(scale, scale, scale);

        renderer.render(scene, camera);
      };
      animate();

      const breathingAnimation = async () => {
        await controls.start({
          opacity: [0.5, 1, 0.5],
          scale: [1, 1.2, 1],
          transition: { duration: 8, repeat: Infinity, ease: "easeInOut" },
        });
      };
      breathingAnimation();

      const timer = setTimeout(() => {
        setStage("explosion");
      }, 24000);

      return () => {
        clearTimeout(timer);
        renderer.dispose();
      };
    }
  }, [isVisible, stage, controls]);

  const handleAnimationComplete = () => {
    if (stage === "explosion") {
      setTimeout(() => setStage("focus"), 2000);
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gradient-to-br from-gray-900 via-purple-900 to-blue-900 bg-opacity-80">
      <AnimatePresence>
        {stage === "breathing" && (
          <motion.div
            key="breathing"
            className="relative w-screen h-screen flex items-center justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div ref={globeRef} className="w-full h-full" />
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={controls}
              className="absolute text-white text-4xl md:text-6xl font-bold text-center"
            >
              Breathe Deeply
            </motion.div>
          </motion.div>
        )}
        {stage === "explosion" && (
          <motion.div
            key="explosion"
            className="relative w-screen h-screen flex items-center justify-center overflow-hidden"
            onAnimationComplete={handleAnimationComplete}
          >
            <motion.div
              initial={{ scale: 1, opacity: 1 }}
              animate={{ scale: 20, opacity: 0 }}
              transition={{ duration: 2, ease: "easeIn" }}
              className="w-64 h-64 rounded-full bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 blur-xl"
            />
            {[...Array(150)].map((_, i) => (
              <motion.div
                key={i}
                initial={{ x: 0, y: 0, opacity: 0, scale: 0 }}
                animate={{
                  x: (Math.random() - 0.5) * window.innerWidth,
                  y: (Math.random() - 0.5) * window.innerHeight,
                  opacity: [0, 1, 0],
                  scale: [0, 1, 0],
                }}
                transition={{ duration: 2, ease: "easeOut" }}
                className="absolute w-2 h-2 rounded-full"
                style={{
                  background: `hsl(${Math.random() * 360}, 100%, 50%)`,
                  boxShadow: "0 0 20px 10px rgba(255, 255, 255, 0.3)",
                }}
              />
            ))}
          </motion.div>
        )}
        {stage === "focus" && (
          <motion.div
            key="focus"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="text-center"
          >
            <motion.div
              animate={{
                scale: [1, 1.05, 1],
                opacity: [0.8, 1, 0.8],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut",
              }}
              className="text-7xl font-bold mb-8 text-transparent bg-clip-text bg-gradient-to-r from-pink-400 via-purple-500 to-blue-500"
            >
              Focus Activated
            </motion.div>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5 }}
              className="text-2xl text-white"
            >
              Your cosmic productivity journey begins
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <button
        onClick={onClose}
        className="absolute top-4 right-4 text-white text-xl hover:text-gray-300 transition-colors"
      >
        ✕
      </button>
    </div>
  );
};

export default ImmersiveBreathingGlobe;
